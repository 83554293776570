<script>
import Layout from "../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import { required, maxLength } from "vuelidate/lib/validators";
export default {
  page: {
    title: "Nouvelle chambre",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Nouvelle chambre",
      blocs: [],
      roomTypes: [],
      items: [
        {
          text: "Infrastructures",
          href: "/",
        },
        {
          text: "Chambres",
          href: "/",
        },
        {
          text: "Nouveau",
          active: true,
        },
      ],
      roomForm: {
        roomNumber: "",
        roomBloc: "",
        roomType: "",
        uuid: null,
        // roomPhone: "",
      },

      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
    };
  },
  validations: {
    roomForm: {
      roomNumber: { required, maxLength:maxLength(64) },
      roomBloc: { required },
      roomType: { required },
    },
    
  },
  created: function () {
    this.getBlocs();
    this.getRoomsTypes();
  },
  mounted() {
    this.roomForm.uuid = this.$route.params.uid;
    this.fetchSingleRoomDetails();
  },
  methods: {
    fetchSingleRoomDetails() {
      var _this = this;
      this.$http
        .post(
          "/infrastructure/rooms/getSingleRoomDetails/" + this.roomForm.uuid
        )

        .then((res) => {
          var status = res.data.original.status;
          var response = res.data.original.room;
          switch (status) {
            case 200:
              _this.roomForm.roomNumber = response.number;
              _this.roomForm.roomBloc = response.bloc_id;
              _this.roomForm.roomType = response.room_type_id;
              break

            case 500:
              this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
          this.$toast.error(error.message);
        })
        .finally(() => {});
    },
    getBlocs() {
      var _this = this;
      this.$http
        .post("/infrastructure/blocs/list")
        .then((res) => {
          _this.blocs = res.data.original.list;
        })
        .catch((error) => {
          Swal.fire("Erreur!", error, "error");
        })
        .finally(() => {});
    },

    getRoomsTypes() {
      var _this = this;
      this.$http
        .post("/infrastructure/room_types/list")
        .then((res) => {
          _this.roomTypes = res.data.original.list;
        })
        .catch((error) => {
          Swal.fire("Erreur!", error, "error");
        })
        .finally(() => {});
    },

    formSubmit() {
      this.submitted = true;
      this.$v.$touch();
      if(!this.$v.$invalid){
      var router = this.$router;
      this.$http
        .post("/infrastructure/rooms/update", this.roomForm)

        .then((res) => {
          var status = res.data.original.status;
          switch (status) {
            case 200:
              this.$toast.success(res.data.original.msg);
              router.push({ name: "base.infrastructures.rooms" });
              break;

            case 500:
              this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
              this.$toast.error(error.message);
        })
        .finally(() => {});
    }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="deptName">Numéro de chambre</label>
                    <input
                      id="deptName"
                      v-model="roomForm.roomNumber"
                      type="text"
                      class="form-control"
                      placeholder=""
                      value=""
                      :class="{
                        'is-invalid':
                          submitted && $v.roomForm.roomNumber.$error,
                      }"
                    />
                    <div v-if="submitted && $v.roomForm.roomNumber.$error" class="invalid-feedback">
                      <span v-if="!$v.roomForm.roomNumber.required">Le numéro de chambre est obligatoire.</span>
                      <span v-if="!$v.roomForm.roomNumber.maxLength" >La taille du champs est limitée a 64 charactères.</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="validationCustom02">Bloc</label>
                    <select
                      v-model="roomForm.roomBloc"
                      :class="{
                        'is-invalid': submitted && $v.roomForm.roomBloc.$error,
                      }"
                      class="custom-select"
                    >
                      <option selected>Sélectionner une classe</option>
                      <option
                        v-for="bloc in blocs"
                        v-bind:value="bloc.id"
                        v-bind:key="bloc.id"
                      >
                        {{ bloc.name }}
                      </option>
                    </select>
                    <div
                      v-if="submitted && $v.roomForm.roomBloc.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.roomForm.roomBloc.required"
                        >Le bloc est obligatoire.</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="validationCustom02">Type</label>
                    <select
                      v-model="roomForm.roomType"
                      :class="{
                        'is-invalid': submitted && $v.roomForm.roomType.$error,
                      }"
                      class="custom-select"
                    >
                      <option selected></option>
                      <option
                        v-for="type in roomTypes"
                        v-bind:value="type.id"
                        v-bind:key="type.id"
                      >
                        {{ type.name }}
                      </option>
                    </select>
                    <div
                      v-if="submitted && $v.roomForm.roomType.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.roomForm.roomType.required"
                        >Le type est obligatoire.</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <button
                class="btn btn-primary"
                type="submit"
              >
                Enregistrer
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>